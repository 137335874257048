import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GlobalSeguridad } from '@seguridad';
import * as normalize from 'normalize-object';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServicioDatosService {

  subsistema: any;
  sistemaActual = sessionStorage.getItem("sistemaLogueado");

  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad,
  ) { }

  servicioSigmaConsulta(sistema: string, idFuncion: string, filtro: string, datosPeticion: string): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacion",
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  servicioSigmaConsultaFiltroUsuario(idFuncion: string, filtro: string): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacionFiltroUsuario",
      {
        idFuncion: idFuncion,
        filtro: filtro,
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    ).pipe(
      map((data: any) => {
        let isObject = {}
        try {
          isObject = normalize(JSON.parse(data?.datos))
        } catch (error) {
          isObject = normalize(data?.datos)
        }
        return isObject
      }));
  }

  getPredecir(parametros: FormData) {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["analiticaData"] + "/prediccion",
      parametros);
  }

  getServicioSigmaConsultaFiltroUsuario(idFuncion: string, filtro: string): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacionFiltroUsuario",
      {
        idFuncion: idFuncion,
        filtro: filtro,
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  servicioObtenerInformacionCache(idObjetoPizarra: string, filtro: string): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlServicioPizarra"] + "/FUNDatosCache/ConsultarInformacionCache",
      {
        idObjetoPizarra: idObjetoPizarra,
        filtro: filtro,
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  servicioRealizarAccion(nombreObjeto: string, jsonData: string, accion: string, url: string): Observable<any> {
    this.seguridadGlobal.validarSesion();
    if (url !== undefined) {
      return this.http.post(
        url,
        {
          nombreObjeto: nombreObjeto,
          jsonDatos: jsonData,
          accion: accion
        },
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
      );
    }
  }

  subirArchivo(fd: FormData): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNArchivo/SubirArchivosFtp",
      fd,
      { headers: new HttpHeaders({ "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  descargarArchivo(url: string, param): Observable<any> {
    this.seguridadGlobal.validarSesion();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Content-Types": this.seguridadGlobal.atributoSesion
    });
    return this.http.post<Blob>(url,
      param, { headers: headers, responseType: 'blob' as 'json' });
  }


  getServicioRealizarAccionGestionResiduos(sistema, nombreObjeto, jsonDatos, jsonDatosPeticion, accion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlServicioGestionResiduos"] + '/FUNGestionResiduos/RealizarAccion',
      {
        sistema: sistema,
        nombreObjeto: nombreObjeto,
        jsonDatos: jsonDatos,
        jsonDatosPeticion: jsonDatosPeticion,
        accion: accion
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  getServicioCargaMasiva(fd): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNArchivo/CargaMasivaV2",
      fd,
      { headers: new HttpHeaders({ "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  getServicioExportarArchivo(url, param): Observable<any> {

    this.seguridadGlobal.validarSesion();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Content-Types": this.seguridadGlobal.atributoSesion
    });

    return this.http.post<Blob>(url,
      param, { headers: headers, responseType: 'blob' as 'json' });
  }


  servicioRealizarAccionSubirArchivo(url: string, fd: FormData): Observable<any> {
    this.seguridadGlobal.validarSesion();
    if (url !== undefined) {
      return this.http.post(
        url,
        fd,
        { headers: new HttpHeaders({ 'Content-Types': this.seguridadGlobal.atributoSesion }) }
      );
    }
  }


  servicioSigmaConsultaSS(sistema: string, idFuncion: string, filtro: string, datosPeticion: string): Observable<any> {
    console.log(this.sistemaActual);

    if (!this.sistemaActual) {
      this.sistemaActual = sistema;
    }

    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacionSS",
      {
        sistema: this.sistemaActual,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }


}
