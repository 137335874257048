<!--<div class="card">
  <ngb-tabset (tabChange)="cambioTabLista.next($event)" [activeId]="tabActivo">
    <div class="card-body">
      <ngb-tab *ngFor="let tab of tabs" title="{{tab.title}}" id="{{tab.id}}">
        <ng-template ngbTabContent class="pt-1">
          <div class="">
            <section *ngIf="columns">

              <div class="btn-group d-flex flex-wrap" [ngClass]="title ? '' : 'justify-content-end'">
                <div class="content-header mt-0 mr-auto" *ngIf="title != ''">
                  <span style="font-size: 1.4rem;">{{title}}</span>
                </div>
                <div>
                  <a (click)="AbrirPopup()" class="badge" [ngClass]="valorFiltroCompuesto.length == 0 ? 'info' : 'danger'" ><em class="fa fa-filter"></em> Filtrar<span *ngIf="valorFiltroCompuesto.length > 0">({{valorFiltroCompuesto.length}})</span> </a>
                <a (click)="ExportarExcel()" class="badge info"><em class="fa fa-download"></em> Exportar</a>
                </div>
              </div>

              <div class="input-group mt-1 mb-0">
                <input type="text" id="txt_filtrar" class="rounded form-control" placeholder="Valor a filtrar"
                  (keyup)='filtrar($event)' (keyup.enter)="GetSearch($event)" [(ngModel)]="filtro">
                <div class="input-group-append" *ngIf="paging === true">
                  <button (click)="FilterFromServer()" class="btn btn-outline-info left-15 mr-1 ft-search">
                  </button>
                </div>
              </div>

              <div>
                <ngx-datatable #table class="bootstrap selection-cell" [rows]="rows" [columns]="columns"
                  [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="size"
                  [scrollbarH]="true" [selected]="selected" [messages]="messages" [externalPaging]="paging"
                  [selectionType]="type" [loadingIndicator]="loading" [offset]="offset" [count]="rowCount"
                  (select)="salida.next($event)" (page)="page.next($event)">
                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                      <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                        [hidden]="!((rowCount / pageSize) > 1)" [count]="rowCount" (change)="table.onFooterPage($event)">
                      </datatable-pager>
                    </ng-template>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>
            </section>
          </div>
        </ng-template>
      </ngb-tab>
    </div>
  </ngb-tabset>
</div>-->

<div class="card">
  <div class="card-header row">
    <nav ngbNav #nav="ngbNav" class="nav nav-tabs nav-fill w-100" [(activeId)]="tabActivo" (tabActivo)="limpiarArreglo()">
      <ng-container ngbNavItem *ngFor="let tab of tabs">
        <li class="nav-item">
          <a ngbNavLink [ngbNavItem]="tab.id" id="{{tab.id}}" (click)="cambioTabLista.next(tab.id)">{{tab.title}}</a>
        </li>
      </ng-container>
    </nav>
  </div>
  <div class="card-body">
    <div class="m-1 p-1">
      <section *ngIf="columns">

        <div class="content-header mt-0" *ngIf="title != ''">
          <span style="font-size: 1.4rem;">{{title}}</span>
        </div>

        <div class="row">
          <div class="input-group col-md-6" *ngIf="mostrarFiltro">
            <a class="card-link info" (click)="AbrirPopup()" title="FILTRO">
              <em class="fa fa-filter"></em>
              FILTRO
            </a>
          </div>

          <div class="input-group col-md-6" *ngIf="mostrarExportar">
            <a class="card-link info" (click)="ExportarExcel()" title="EXPORTAR">
              <em class="fa fa-file"></em>
              EXPORTAR
            </a>
          </div>
        </div>


        <div class="input-group mt-1 mb-0">
          <input type="text" id="filtroV3" class="rounded form-control" placeholder="Valor a filtrar"
            (keyup)='filtrar($event)' (keyup.enter)="GetSearch($event)" [(ngModel)]="filtro">
          <div class="input-group-append" *ngIf="paging === true">
            <button (click)="FilterFromServer()" class="btn btn-outline-info left-15 mr-1 ft-search">
            </button>
          </div>
        </div>

        <div>
          <ngx-datatable #table class="bootstrap selection-cell" [rows]="rows" [columns]="columns"
            [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="size"
            [scrollbarH]="true" [selected]="selected" [messages]="messages" [externalPaging]="paging"
            [selectionType]="type" [loadingIndicator]="loading" [offset]="offset" [count]="rowCount"
            (select)="salida.next($event)" (page)="page.next($event)">
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                  [hidden]="!((rowCount / pageSize) > 1)" [count]="rowCount" (change)="table.onFooterPage($event)">
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </section>
    </div>
  </div>
</div>
