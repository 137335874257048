import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors } from '@angular/forms';
import { Mensaje } from "@mensaje";
import { ServicioUtilidadesService } from 'app/shared/services/servicio-utilidades.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsList } from '@shared/components/listas/settings-list';
import { ServicioModuloGeneralService } from '@shared/services/servicio-modulo-general.service';
import { ServicioValidarCoorSancioService } from './servicio-validacion-coor-sancio.service';


@Component({
  selector: 'app-frm-validacion-coordenadas-sancionatorio',
  templateUrl: './frm-validacion-coordenadas-sancionatorio.component.html',
  styleUrls: ['./frm-validacion-coordenadas-sancionatorio.component.scss']
})
export class FrmValidacionCoordenadasSancionatorioComponent implements OnInit {

  arrEstado: any = [];
  bloqueDominios: any = true;
  bloqueoBoton: any = true;
  mensaje: Mensaje;
  modelo: any;
  modeloTemp: any;
  regularForm: FormGroup;
  resultadosDominios: any;
  san_codigo: any = "";
  per_estado: any;
  mensajeCargaMasiva: any;
  fileInput: any;
  archivoSeleccionadoOperacion: File = null;

  usuario = JSON.parse(sessionStorage.infoUsuario).usu_codigo;
  perfil = JSON.parse(sessionStorage.infoUsuario).per_codigo;



  //Configuración para lista V3
  configuracionLista = new SettingsList();
  rows: any;
  columns: any;
  filter: any;

  idsSigmaConsulta = {
    sigmaConsultaPaginacion: "LISTA_PAGINACION_VALIDAR_COORDENADAS_SANCIONATORIO_BOY",
    sigmaConsulta: "LISTA_VALIDAR_COORDENADAS_SANCIONATORIO_BOY"
  };
  resultadoConfigFiltroCompuesto: any;

  filtroPag = { "filtro": "%%", "filtroCompuesto": "" };
  filtro = { "offset": "0", "limite": "", "filtro": "%%", "filtroCompuesto": "" };
  temp_configuracionColumnas = '[{"prop":"fev_codigo","name":"Código"},{"prop":"fev_estado","name":"Estado"},{"prop":"fev_json.fev_obstec","name":"Observaciones técnico"},{"prop":"fev_json.fev_obsgis","name":"Observaciones GIS"}]';



  constructor(
    private servicio: ServicioValidarCoorSancioService,
    private servicioCom: ServicioModuloGeneralService,
    private utilidades: ServicioUtilidadesService,
  ) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit() {
    this.servicioCom.hijo = this;
    this.InstanciarModelo();
    // this.configuracionColumnasListas = JSON.parse(this.temp_configuracionColumnas);
    //this.elementosListaInformacion = JSON.parse('[]');
    this.columns = JSON.parse(this.temp_configuracionColumnas);
    this.rows = [];
    this.configuracionLista.paging = true;
    this.regularForm = new FormGroup({
      'fev_codigo': new FormControl(null, this.LimpiarControl),
      'san_codigo': new FormControl(null, this.LimpiarControl),
      'fev_estado': new FormControl(null, [Validators.required]),
      'fev_obstec': new FormControl(null, [Validators.required]),
      'fev_obsgis': new FormControl(null, this.LimpiarControl),
    }, { updateOn: 'change' });

    this.regularForm.controls.fev_codigo.disable();
    this.regularForm.controls.san_codigo.disable();
    this.regularForm.controls.fev_estado.disable();
    this.regularForm.controls.fev_obsgis.disable();

    if (this.perfil == "PER_01" || this.perfil == "PER_13") {
      this.regularForm.controls.fev_estado.enable();
      this.regularForm.controls.fev_obsgis.enable();
    }



  }

  LimpiarControl(control: AbstractControl): ValidationErrors | null {
    try {
      return control.value === '' || control.value == null ? null : Validators.required(control);
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  InstanciarModelo() {

    try {
      this.ObtenerModelo();
      if (this.resultadosDominios == undefined) {
        this.ObtenerDominios();
        this.bloqueDominios = false;
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
  * Obtiene el modelo vacio.
  * @author Esteban Muñoz
  * @date 2021-01-16
  * @param
  * @returns
  */
  ObtenerModelo() {
    try {
      if (!this.modelo) {
        this.LimpiarModelo();
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
   * Asigna el modelo seteado desde un servicio
   * @author Esteban Muñoz
   * @date 2021-01-16
   * @param modelo
   * @returns Respuesta del servicio de la capa funcional
  */
  AsignarModelo(modelo: any) {
    try {
      if (modelo != undefined) {
        this.san_codigo = JSON.parse(JSON.stringify(modelo)).san_codigo;
        this.modelo.san_codigo = this.san_codigo;
        this.filtro["san_codigo"] = this.san_codigo
        this.filtroPag["san_codigo"] = this.san_codigo
        this.configuracionLista.title = `Sancionatorio: ${this.san_codigo}`;
        this.bloqueoBoton = true;
        this.modelo["vis_codigo"] = this.servicioCom.vis_codigo;
        this.ObtenerCantidadElementos();
        this.LimpiarModelo2();
      } else {
        this.InstanciarModelo();

      }
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  BloquearCampos() {
    if (this.modelo.fev_estado == "Aprobado") {
      this.regularForm.controls.fev_codigo.disable();
      this.regularForm.controls.san_codigo.disable();
      this.regularForm.controls.fev_estado.disable();
      this.regularForm.controls.fev_obstec.disable();
      this.regularForm.controls.fev_obsgis.disable();

    } else {
      this.regularForm.controls.fev_codigo.disable();
      this.regularForm.controls.san_codigo.disable();
      this.regularForm.controls.fev_estado.disable();
      this.regularForm.controls.fev_obsgis.disable();
      this.regularForm.controls.fev_obstec.enable();

      if (this.perfil == "PER_01" || this.perfil == "PER_13") {
        this.regularForm.controls.fev_estado.enable();
        this.regularForm.controls.fev_obsgis.enable();
      }
    }
  }

  ObjetoListaSeleccionado(objeto) {
    let registro = objeto.selected[0];
    this.modelo.fev_codigo = registro.fev_codigo;
    this.modelo.san_codigo = registro.san_codigo;
    this.modelo.fev_estado = registro.fev_estado;
    this.modelo.fev_fecha = registro.fev_fecha;
    this.modelo.fev_fecedi = registro.fev_fecedi;
    this.modelo.fev_json = registro.fev_json;
    this.bloqueoBoton = false;

    this.BloquearCampos();

  }




  /**
  * Descripción del metodo LimpiarModelo, limpia el modelo y lo setea de nuevo todo vacío
  * @author Esteban Muñoz
  * @date 2021-01-16.
  * @param
  * @returns
  */
  LimpiarModelo() {
    try {
      let filtro = "VALIDACION_COORDENADAS";
      this.servicio.getServicioObtenerInformacionCache("", "MODELOS", filtro, "").subscribe(resultado => {
        if (resultado.respuesta == "true") {
          this.modelo = this.utilidades.RetornarStringObjeto(resultado.datos);
          this.modeloTemp = this.utilidades.RetornarStringObjeto(resultado.datos);
          this.bloqueoBoton = true;
          this.modelo.san_codigo = this.servicioCom.codigoSancionatorio;
          this.modelo.fev_estado = "Enviado";
          this.modelo["pre_codigo"] = this.servicioCom.codigoPredio;
          this.modelo["vis_codigo"] = this.servicioCom.vis_codigo;
          this.BloquearCampos();


        }
        this.servicioCom.ObtenerModelo();
      });

    } catch (e) {
      console.log("Error :" + e);
    }
  }

  LimpiarModelo2() {
    try {
      let filtro = "VALIDACION_COORDENADAS";
      this.servicio.getServicioObtenerInformacionCache("", "MODELOS", filtro, "").subscribe(resultado => {
        if (resultado.respuesta == "true") {
          this.modelo = this.utilidades.RetornarStringObjeto(resultado.datos);
          this.modeloTemp = this.utilidades.RetornarStringObjeto(resultado.datos);
          this.bloqueoBoton = true;
          this.modelo.san_codigo = this.servicioCom.codigoSancionatorio;
          this.modelo.fev_estado = "Enviado";
          this.modelo["pre_codigo"] = this.servicioCom.codigoPredio;
          this.BloquearCampos();
          this.modelo["vis_codigo"] = this.servicioCom.vis_codigo;

          this.regularForm.controls.fev_codigo.disable();
          this.regularForm.controls.per_codigo.disable();
          this.regularForm.controls.san_codigo.disable();
          this.regularForm.controls.fev_obsgis.disable();
          this.regularForm.controls.fev_obstec.enable();

          if (this.perfil == "PER_01" || this.perfil == "PER_13") {
            this.regularForm.controls.fev_estado.enable();
            this.regularForm.controls.fev_obsgis.enable();
          }

        }
      });


    } catch (e) {
      console.log("Error :" + e);
    }
  }

  async ObtenerDominios() {
    try {
      const filtro = 'EstadoCoordenadas';
      const dominios = await this.servicio.getServicioObtenerInformacionCache('', 'DOMINIOS', filtro, '').toPromise();
      if (dominios.respuesta === 'true') {
        this.resultadosDominios = this.utilidades.RetornarStringObjeto(dominios.datos);
        this.arrEstado = this.resultadosDominios.EstadoCoordenadas;


        this.bloqueDominios = false;
      };

    } catch (e) {
      console.log('Error: ' + e);
    }
  }
  /**
   * Descripción del método GetServicioDatosGuardar llamado del botón del HTML.
   * @author Esteban Muñoz.
   * @date 2021-01-16.
   * @param
   * @returns
  */
  GetServicioDatosGuardar() {
    try {


      let parametros = {
        contenedor: this,
        datos: {
          accion: "adicionar",
          modelo: this.modelo
        }
      };

      this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea adicionar el registro?", "", "", this, "ConfirmadoGetServicioDatosAccion", parametros);


    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
   * Descripción del método GetServicioDatosEditar llamado del botón del HTML.
   * @author Esteban Muñoz.
   * @date 2021-01-16.
   * @param
   * @returns
  */
  GetServicioDatosEditar() {
    try {


      let parametros = {
        contenedor: this,
        datos: {
          accion: "editar",
          modelo: this.modelo
        }
      };

      this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea editar el registro: " + this.modelo.fev_codigo + "?", "", "", this, "ConfirmadoGetServicioDatosAccion", parametros);


    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
   * Descripción del método GetServicioDatosEliminar llamado del botón del HTML.
   * @author Esteban Muñoz.
   * @date 2021-01-16.
   * @param
   * @returns
  */
  GetServicioDatosEliminar() {
    try {
      let parametros = {
        contenedor: this,
        datos: {
          accion: "eliminar",
          modelo: this.modelo
        }
      };

      this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea eliminar el registro: " + this.modelo.fev_codigo + "?", "", "", this, "ConfirmadoGetServicioDatosAccion", parametros);
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  /**
  * Descripción del método ConfirmadoGetServicioDatosAccion llamado desde otras funcion,
  * en este caso las que empiezan por Get.. recibe los parametros que tiene el contenedor
  * @author Esteban Muñoz.
  * @date 2021-01-16.
  * @param Parametros del contendor
  * @returns Respuesta del servicio de la capa funcional
  */
  ConfirmadoGetServicioDatosAccion(parametros) {
    try {
      parametros.contenedor.modeloActual = JSON.stringify(parametros.contenedor.modelo);
      parametros.contenedor.mensaje.retornarMensajeFuncion("loading", "Realizando petición", "", "", null, null, null);
      parametros.contenedor.servicio
        .getServicioRealizarAccion(
          "",
          "validar-coordenadas",
          parametros.contenedor.modeloActual,
          parametros.contenedor.datosPeticion,
          parametros.datos.accion
        )
        .subscribe(
          (data) => {
            parametros.contenedor.mensaje.cerrarMensajeCarga();
            if (data.respuesta == "true") {
              let elemento = data.sql;
              parametros.contenedor.mensaje.retornarMensaje(
                "exito",
                "Operación exitosa",
                "Éxito al " +
                parametros.datos.accion +
                " la validación de la coordenada " +
                elemento +
                "",
                ""
              );
              parametros.contenedor.modelo.fev_codigo = elemento;
              parametros.contenedor.bloqueoBoton = false;
              parametros.contenedor.ObtenerCantidadElementos();


              if (parametros.datos.accion == "eliminar") {
                parametros.contenedor.LimpiarModelo2();
                parametros.contenedor.bloqueoBoton = true;
              }
              parametros.contenedor.BloquearCampos();
            } else {
              if (data.noticia != "") {
                parametros.contenedor.mensaje.retornarMensaje(
                  "error",
                  "Atención",
                  data.noticia,
                  ""
                );
              } else {
                parametros.contenedor.mensaje.retornarMensaje(
                  "error",
                  "Operación Incorrecta",
                  "Ocurrió un error al " + parametros.datos.accion + " el registro",
                  ""
                );
              }
            }
          },
          (err: HttpErrorResponse) => {
            parametros.contenedor.mensaje.cerrarMensajeCarga();
            console.log(err);
          }
        );
    } catch (e) {
      console.log("Error: " + e);
    }
  }



  ObtenerCantidadElementos() {
    this.configuracionLista.loading = true;
    let idSigmaConsulta = this.idsSigmaConsulta.sigmaConsultaPaginacion;
    let filtro = JSON.stringify(this.filtroPag);
    filtro = JSON.parse(filtro);
    if (this.filter) {
      filtro["filtro"] = `%${this.filter}%`;
    }
    filtro["codigo"] = this.modelo.san_codigo;
    filtro = JSON.stringify(filtro);
    this.servicio.getServicioSigmaConsulta("", idSigmaConsulta, filtro, "")
      .subscribe(res => {
        if (res.respuesta == "true") {
          let respuesta = this.utilidades.RetornarStringObjeto(res.datos).datos;
          this.configuracionLista.totalElements = respuesta[0].total;
          this.configuracionLista = { ...this.configuracionLista };
          this.SetData({ offset: 0 });
        }
      });
  }


  SetData(pageInfo) {
    this.resultadoConfigFiltroCompuesto = this.idsSigmaConsulta.sigmaConsulta;
    this.configuracionLista.pageNumber = pageInfo.offset;
    const limiteAux = this.configuracionLista.size;
    const offset = this.configuracionLista.pageNumber;
    let idSigmaConsulta = this.idsSigmaConsulta.sigmaConsulta;
    let filtro = JSON.stringify(this.filtro);
    filtro = JSON.parse(filtro);
    filtro["limite"] = String(limiteAux);
    filtro["offset"] = String(offset);
    if (this.filter) {
      filtro["filtro"] = `%${this.filter}%`;
    }
    filtro["codigo"] = this.modelo.san_codigo;
    filtro = JSON.stringify(filtro);
    this.servicio.getServicioSigmaConsulta("", idSigmaConsulta, filtro, "")
      .subscribe(res => {
        if (res.respuesta == "true") {
          let respuesta = this.utilidades.RetornarStringObjeto(res.datos).datos;
          let coleccionDatosLista = [];
          for (let i in respuesta) {
            coleccionDatosLista.push(respuesta[i]);
          }
          this.rows = coleccionDatosLista;
        } else {
          this.rows = [];
        }
        this.configuracionLista.loading = false;
      });
  }


  Filter(filter) {
    this.filter = filter;
    this.ObtenerCantidadElementos();
  }


  FiltroCompuesto(filter) {
    this.filtroPag["filtroCompuesto"] = JSON.stringify(filter);
    this.filtro["filtroCompuesto"] = JSON.stringify(filter);
    this.ObtenerCantidadElementos();
  }

  ExportarFiltro(filter) {
    let idSigmaConsultaExportar = filter["idSigmaConsultaExportar"];

    this.mensaje.retornarMensajeFuncion("loading", "Realizando petición", "", "", null, null, null);
    let filtro = this.filtro;
    let tipo = "xls";
    let sistema = sessionStorage.getItem("sistemaLogueado");
    let nombreArchivo = "ReporteLista";
    let url = JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNArchivo/ExportarArchivo";

    let param = { "sistema": sistema, "sigmaConsulta": idSigmaConsultaExportar, "filtro": JSON.stringify(filtro), "tipo": tipo, "nombreArchivo": nombreArchivo };
    try {
      this.servicio.descargarArchivo(url, param).subscribe(
        (val) => {
          let blob = new Blob([val], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          let url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.download = nombreArchivo + ".xlsx";
          a.href = url;
          a.target = "_blank";
          a.click();
          a.remove();
        },
        response => {
          console.log("POST in error", response);
        },
        () => {
          console.log("POST observable is now completed.");
        });
    } catch (err) {
      console.log("Error Descarga:" + err);
    }
  }

}
