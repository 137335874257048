import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { loadInfoLayer, setInfoLayer, failLoadingInfoLayer } from '../actions';
import { VisorDatosService } from '../../shared/services/visor-datos.service';
import { from, of } from 'rxjs';
import * as esri from 'esri-leaflet';
import L, { Projection } from 'leaflet';

@Injectable()
export class InfoLayerEffects {
    @Effect()
    data$ = this.actions$.pipe(
        ofType(loadInfoLayer),
        mergeMap((payload: { url: string, serviceName: string, activeLayers: Array<any>, esri?: { bbox: string } }) => {
            if (payload.esri) {
                return from(new Promise((resolve, reject) => {
                    const querys: { query: esri.Query, serviceName: string, layerId: string, prop?: any, buttons?: any, domains?: any }[] = [];
                    payload.activeLayers.forEach(async x => {
                        const urls: string[] = [];
                        if (x?.tieneAgrupamientos) {
                            x.agrupamientos.forEach((agrupamiento: any) => {
                                agrupamiento.subcapas.forEach((subcapa: any) => {
                                    if (subcapa.activo) {
                                        let query = new esri.Query({ useCors: true, url: payload.url + '/' + subcapa.id });
                                        urls.push(payload.url + '/' + subcapa.id + "?f=json");
                                        query = this.setBox(payload.esri.bbox, query);
                                        querys.push({ query, serviceName: payload.serviceName, layerId: subcapa.id, prop: undefined, buttons: subcapa.buttons });
                                    }
                                });
                            });
                        } else {
                            if (x.activo) {
                                let query = new esri.Query({ useCors: true, url: payload.url + '/' + x.id });
                                query = this.setBox(payload.esri.bbox, query);
                                urls.push(payload.url + '/' + x.id + "?f=json");
                                querys.push({ query, serviceName: payload.serviceName, layerId: x.id, prop: undefined, buttons: x.buttons });
                            }
                        }
                        for (let index = 0; index < urls.length; index++) {
                            const url = urls[index];
                            let response = await this.visorDatosService.geoserverQuery(url).toPromise();
                            querys[index].domains = response;
                        }
                        this.executeQuerys(querys, resolve, []);
                    });
                }));
            }
            return this.visorDatosService
                .geoserverQuery(payload.url)
                .pipe(
                    map(data => {
                        const features = data?.features?.map((feature: { id: string, properties: object }) => {
                            const layerId = feature?.id.split('.')[0];
                            const layer = [];
                            payload.activeLayers.forEach(x => {
                                if (x?.tieneAgrupamientos) {
                                    x.agrupamientos.forEach((agrupamiento: any) => {
                                        agrupamiento.subcapas.forEach((subcapa: any) => {
                                            if (subcapa.id === layerId) {
                                                layer.push(subcapa);
                                            }
                                        });
                                    });
                                } else {
                                    if (x.id === layerId) {
                                        layer.push(x);
                                    }
                                }
                            });

                            let prop: any = undefined;
                            layer[0]?.info?.campos.forEach(x => {
                                if (feature?.properties.hasOwnProperty(x.id)) {
                                    if (prop === undefined) {
                                        prop = {}
                                    }
                                    prop[x.alias] = feature?.properties[x.id];
                                }
                            })
                            return {
                                serviceName: payload.serviceName,
                                layerId: layerId,
                                properties: prop !== undefined ? prop : feature?.properties,
                                buttons: layer[0]?.info?.botones
                            };
                        });
                        return setInfoLayer({ payload: features })
                    }),
                    catchError((error: any) => of(failLoadingInfoLayer({ error: error?.error?.text }))),
                )
        }
        )
    );

    executeQuerys(querys: { query: esri.Query, serviceName: string, layerId: string, prop?: any, buttons?: any, domains?: any }[], resolve, responses: any[] = [], index?: number) {
        if (index === undefined) {
            index = 0;
        }
        if (querys.length > 0) {
            querys[index].query.returnGeometry(true).run((error, featureCollection, response) => {
                if (error) {
                    console.log(error);
                } else {
                    let features = featureCollection.features.map((feature: { id: string, properties: object }) => {
                        querys[index].domains.fields.filter((x: any) => x?.domain).forEach((field: any) => {
                            if (feature.properties[field.name] !== undefined) {
                                feature.properties[field.name] = field.domain.codedValues.find((x: any) => {
                                    const value = x.code === feature.properties[field.name];
                                    if (value) {
                                        console.log("funciona");
                                        
                                    }
                                    return value;
                                })?.name;
                            }
                        });

                        return {
                            serviceName: querys[index].serviceName,
                            layerId: querys[index].layerId,
                            properties: querys[index].prop !== undefined ? querys[index].prop : feature?.properties,
                            buttons: querys[index].buttons
                        };
                    });

                    responses.push(features);
                    if (index < querys.length - 1) {
                        this.executeQuerys(querys, resolve, responses, index + 1);
                    } else {
                        return resolve(setInfoLayer({ payload: features }));
                    }
                }
            });
        }
    }

    setBox(bbox: string, query: esri.Query) {
        if (bbox != "") {
            const arrBound = bbox.split(',');
            const corner1 = new L.Point(parseFloat(arrBound[0]), parseFloat(arrBound[1]));
            const corner2 = new L.Point(parseFloat(arrBound[2]), parseFloat(arrBound[3]));
            const bounds = L.latLngBounds(Projection.SphericalMercator.unproject(corner1), Projection.SphericalMercator.unproject(corner2));

            const poligonoAux = L.rectangle(bounds);
            query.bboxIntersects(poligonoAux);
            return query
        }
    }

    constructor(private actions$: Actions, private visorDatosService: VisorDatosService) { }
}
