<form class="form" novalidate [formGroup]="regularForm" *ngIf="modelo != undefined">
  <div class="form-body">
    <div id="lista-predios">
      <app-lista-informacion-v3 [rows]="rows" [columns]="columns" [settings]="configuracionLista"
        (salida)="ObjetoListaSeleccionado($event)" (page)="SetData($event)" (filter)="Filter($event)"
        (filtroCompuesto)="FiltroCompuesto($event)" (exportarFiltro)="ExportarFiltro($event)"
        [idSigmaConsultaFiltro]="resultadoConfigFiltroCompuesto">
      </app-lista-informacion-v3>
    </div>
    <div id="predio">
      <h4 class="form-section"><em class="ft-compass"></em>Validación coordenadas</h4>
      <div class="row">


        <div class="form-group col-md-6 mb-2">
          <label for="fev_codigo">Código</label>
          <input type="text" id="fev_codigo" class="form-control" formControlName="fev_codigo" placeholder=""
            name="fev_codigo" [(ngModel)]="modelo.fev_codigo">
        </div>

        <div class="form-group col-md-6 mb-2">
          <label for="san_codigo">Código sancionatorio</label>
          <input type="text" id="san_codigo" class="form-control" formControlName="san_codigo" placeholder=""
            name="san_codigo" [(ngModel)]="modelo.san_codigo">
        </div>


        <div class="form-group col-md-6 mb-2">
          <label for="fev_estado">Estado <strong class="requerido">*</strong> </label>
          <ng-select bindLabel="text" bindValue="id" [loading]="bloqueDominios" [items]="arrEstado"
            formControlName="fev_estado" [(ngModel)]="modelo.fev_estado">
          </ng-select>
        </div>


        <div class="form-group col-md-6 mb-2">
          <label for="fev_obstec">Observaciones técnico <strong class="requerido">*</strong> </label>
          <textarea id="fev_obstec" rows="5" class="form-control block-textarea" formControlName="fev_obstec"
            name="fev_obstec" placeholder="" [(ngModel)]="modelo.fev_json.fev_obstec" blockSpecialChars></textarea>
        </div>


        <div class="form-group col-md-6 mb-2">
          <label for="fev_obsgis">Observaciones GIS</label>
          <textarea id="fev_obsgis" rows="5" class="form-control block-textarea" formControlName="fev_obsgis"
            name="fev_obsgis" placeholder="" [(ngModel)]="modelo.fev_json.fev_obsgis" blockSpecialChars></textarea>
        </div>


      </div>



    </div>
  </div>
  <div class="form-actions">
    <small class="form-text text-muted danger" *ngIf="!regularForm.valid">Recuerde llenar todos los campos
      requeridos</small>
    <span *ngIf="bloqueoBoton; else edicion">
      <button type="button" (click)="GetServicioDatosGuardar()" class="btn btn-raised btn-primary mr-1"
        [disabled]="!regularForm.valid">
        <em class="fa fa-check-square-o"></em> Guardar
      </button>
    </span>
    <ng-template #edicion>
      <button type="button" (click)="GetServicioDatosEditar()" class="btn btn-raised btn-warning mr-1"
        [disabled]="!regularForm.valid">
        Editar
      </button>
      <button type="button" (click)="GetServicioDatosEliminar()" class="btn btn-raised btn-danger mr-1">
        Eliminar
      </button>
    </ng-template>
    <span *ngIf="perfil == 'PER_01' || perfil=='PER_13'">
      <button type="button" (click)="LimpiarModelo()" class="btn btn-default" aria-label="Left Align">
        <span class="glyphicon glyphicon-align-left" aria-hidden="true"></span>
        Limpiar
      </button>
    </span>
  </div>

</form>
