import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GlobalSeguridad } from '@seguridad';
import { isEmpty } from 'lodash';
import * as normalize from 'normalize-object';
import { map, tap } from 'rxjs/operators';
import { IHistoricalGpsRequest, IHistoricalGpsResponse } from '../../store';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class VisorDatosService {
    header: any = {};
    urlSigmaConsulta: string;
    urlSigmaHistoricalGps: string;
    urlSigmaConsultaFiltro: string;
    constructor(private http: HttpClient, private seguridadGlobal: GlobalSeguridad) {
        this.urlSigmaConsulta = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion';
        this.urlSigmaHistoricalGps = JSON.parse(sessionStorage.getItem('webConfig'))['servicioGps'];
        this.header = { "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion };
    }
    /**
   * Obtiene los campos de una capa
   * @author Santiago Hernandez
   * @date 27/02/2022
   * @param
   * @returns
   */
  ObtenerCampos(url: string, urlCapa: string): Observable<any> {
    const headersT = new HttpHeaders();
    headersT.append("Access-Control-Allow-Headers", "Content-Type");
    headersT.append("Access-Control-Allow-Methods", "GET");
    headersT.append("Access-Control-Allow-Origin", "*");

    const urlEncode = encodeURIComponent(urlCapa);
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).urlOuterLayer + url + urlEncode, {
      headers: new HttpHeaders({
        'Content-Types': this.seguridadGlobal.atributoSesion
      })
    });
  }
    geoserverQuery(url: string): Observable<any> {
        const headersT = new HttpHeaders();
        headersT.append('Access-Control-Allow-Headers', 'Content-Type');
        headersT.append('Access-Control-Allow-Methods', 'POST');
        headersT.append('Access-Control-Allow-Origin', '*');

        return this.http.get(url, { headers: headersT });
    }

    getConfigLeaflet(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
        this.seguridadGlobal.validarSesion();
        return this.http
            .post(
                this.urlSigmaConsulta,
                {
                    sistema: sistema,
                    idFuncion: idFuncion,
                    filtro: filtro,
                    datosPeticion: datosPeticion,
                },
                { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
            )
            .pipe(
                map((configLeaflet: any) => {
                    let isObject = {}
                    try {
                        isObject = normalize(JSON.parse(configLeaflet?.datos))
                    } catch (error) {
                        isObject = normalize(configLeaflet?.datos)
                    }
                    return isObject
                }),
                map((configLeaflet: { mapa: Array<any>; servicio: Array<any> }) => {
                    if (!isEmpty(configLeaflet) && configLeaflet?.mapa?.length > 0) {
                        return {
                            map: configLeaflet?.mapa[0],
                            service: configLeaflet?.servicio.map(x => (
                                {
                                    ...x?.capJson,
                                    collapsed: false,
                                    serCapasActivas: x?.capJson?.serCapasActivas?.map(c => ({ ...c, collapsed: false }))
                                }))
                        };
                    }
                    return undefined;
                })
            );
    }

    getConfigForHistoricalGps(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
        this.seguridadGlobal.validarSesion();
        return this.http
            .post(
                this.urlSigmaConsulta,
                {
                    sistema: sistema,
                    idFuncion: idFuncion,
                    filtro: filtro,
                    datosPeticion: datosPeticion,
                },
                { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
            )
    }

    enviarRespuesta(registerNews: any): Observable<any> {
        const url = JSON.parse(sessionStorage.getItem('webConfig'))['servicioNotificacion'] + '/FUNMonitoreo/RespuestaNotificacion';
        this.seguridadGlobal.validarSesion();
        return this.http.post(
            url,
            { ...registerNews },
            { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
        );
    }

    getHistoricalGps(sistema: string, historicalGpsRequest: IHistoricalGpsRequest) {
        this.seguridadGlobal.validarSesion();
        return this.http
            .post(
                this.urlSigmaHistoricalGps,
                {
                    sistema: sistema,
                    parametros: JSON.stringify(historicalGpsRequest)
                },
                { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
            ).pipe(
                map((historicalGps: Array<IHistoricalGpsResponse>) => historicalGps.filter((d: IHistoricalGpsResponse) => {
                    return d.Eventos.features.length > 0
                })),
                map((historicalGps: Array<IHistoricalGpsResponse>) => (
                    historicalGps.map(historical => ({
                        ...historical,
                        color: this.getRandmonColor()
                    }))
                )),
                tap((historicalGps: Array<IHistoricalGpsResponse>) => this.showAlertWhenNoDataFound(historicalGps))
            )
    }

    getRandmonColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return `#${randomColor}`;
    }

    private showAlertWhenNoDataFound(historicalGps: Object) {
        if (isEmpty(historicalGps)) {
            const toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true
            });

            toast.fire({ icon: 'error', title: 'Gps no transmitio para ese rango de fechas.' });
        }
    }

    getServicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
        this.seguridadGlobal.validarSesion();
        return this.http.post(
            JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacion",
            {
                sistema: sistema,
                idFuncion: idFuncion,
                filtro: filtro,
                datosPeticion: datosPeticion
            },
            //httpOptions
            { headers: new HttpHeaders(this.header) }
        );
    }

    servicioObtenerInformacionCache(idObjetoPizarra, filtro, datosPeticion): Observable<any> {
        this.urlSigmaConsulta = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion';
        this.urlSigmaConsultaFiltro = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacionFiltros';
        return this.http.post(
            JSON.parse(sessionStorage.getItem('webConfig'))['urlServicioPizarra'] + '/FUNDatosCache/ConsultarInformacionCache',
            {
                idObjetoPizarra: idObjetoPizarra,
                filtro: filtro,
                datosPeticion: datosPeticion
            },
            // httpOptions
            { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
        );
    }

    servicioSigmaConsultaFiltro(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
        this.urlSigmaConsulta = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion';
        this.urlSigmaConsultaFiltro = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacionFiltros';
        this.seguridadGlobal.validarSesion();
        return this.http.post(
            this.urlSigmaConsultaFiltro,
            {
                sistema: sistema,
                idFuncion: idFuncion,
                filtro: filtro,
                datosPeticion: datosPeticion
            },
            // httpOptions
            { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
        );
    }
}
