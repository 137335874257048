import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NguCarouselModule } from '@ngu/carousel';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar-original/sidebar.component";
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';

//Componentes SIGMA
import { CargaMasivaComponent } from './components/carga-masiva/carga-masiva-v1/carga-masiva.component';
import { GraficaComponent } from './components/graficas/grafica-v1/grafica.component';
import { ListaInformacionComponent } from './components/listas/lista-informacion-v1/lista-informacion.component';
import { ListaNotificacionComponent } from './components/notificaciones/lista-notificacion/lista-notificacion.component';
import { ListaNotificacionGruposComponent } from './components/notificaciones/lista-notificacion-grupos/lista-notificacion-grupos.component';
import { NavbarNotificacionComponent } from './components/navbar-notificacion/navbar-notificacion.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones.component';
import { ObjetosControlComponent } from './components/objetos-control/objetos-control-v1/objetos-control.component';
import { ObjetosControlComponentV2 } from './components/objetos-control/objetos-control-v2/objetos-control-v2.component';
import { SubsistemasComponent } from './components/subsistemas/subsistemas-v1/subsistemas.component';
import { SubsistemasComponentV2 } from './components/subsistemas/subsistemas-v2/subsistemas-v2.component';
import { BlockSpecialCharsDirective } from './directives/block-special-character.directive';
import { AllowOnlyNumberDirective } from './directives/allow-only-number.directive';
import { KeyCombineDirective } from './directives/key-combine.directive';

import { PopupResumenListaComponent } from './components/popups/popup-resumen-lista/popup-resumen-lista.component';
import { PopupResumenListaV2Component } from './components/popups/popup-resumen-lista-v2/popup-resumen-lista-v2.component';
import { MultiDatePickerComponent } from './components/calendarios/multi-date-picker/multi-date-picker';
import { ResumenInformacionComponent } from './components/resumen/resumen-informacion-v1/resumen-informacion.component';
import { ListaInformacionMultipleTabsComponent } from './components/listas/lista-informacion-multiple-tabs-v1/lista-informacion-multiple-tabs.component';
import { ListaInformacionTabsComponent } from './components/listas/lista-informacion-tabs-v1/lista-informacion-tabs.component';
import { ListaInformacionV2Component } from './components/listas/lista-informacion-v2/lista-informacion-v2.component';
import { CarruselImagenesComponent } from './components/carrusel-imagenes/carrusel-imagenes-v1/carrusel-imagenes.component';
import { GraficaV2Component } from './components/graficas/grafica-v2/grafica-v2.component';
import { CarruselImagenesConsultaComponent } from './components/carrusel-imagenes/carrusel-imagenes-consulta-v1/carrusel-imagenes-consulta.component';
import { DefaultPipe } from './pipes/default.pipe';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { CargadorIframeComponent } from './components/contenedores/cargador-iframe/cargador-iframe.component';
import { ListaInformacionMultipleComponent } from './components/listas/lista-informacion-multiple-v1/lista-informacion-multiple.component';
import { ReporteadorComponent } from './components/reporteadores/reporteador/reporteador.component';
import { PopupReporteadorSencilloComponent } from './components/popups/popup-reporteador-sencillo/popup-reporteador-sencillo.component';
import { PopupComboComponent } from './components/popups/popup-combo/popup-combo.component';
import { TarjetaPrincipalComponent } from './components/tarjeta-principal/tarjeta-principal-v1/tarjeta-principal.component';
import { PopupCarmasComponent } from './components/popups/popup-carmas/popup-carmas.component';
import { ChartsModule } from 'ng2-charts';
import { FiltroCompuestoComponent } from './components/filtros/filtro-compuesto/filtro-compuesto.component';
import { BarraHerramComponent } from './components/barra-herramientas/barra-herram/barra-herram.component';
import { BarraHerramientasService } from './components/barra-herramientas/barra-herram/barra-herram.service';

import { MensajesPipe } from './pipes/mensajes.pipe';
import { TarjetIndicaComponent } from './components/indicadores/tarjet-indica/tarjet-indica.component';
import { PopupComboListaComponent } from './components/popups/popup-combo-lista/popup-combo-lista.component';
import { CarruselImagenesV2Component } from './components/carrusel-imagenes/carrusel-imagenes-v2/carrusel-imagenes-v2.component';
import { FilterCategory, CountNotificationCategory } from './notification-sidebar/filter-category.pipe';
import { CounterBubbleComponent } from './components/counter-bubble/counter-bubble.component';
import { ListaInformacionV3Component } from './components/listas/lista-informacion-v3/lista-informacion-v3.component';
import { ListaInformacionTabsV3Component } from './components/listas/lista-informacion-tabs-v3/lista-informacion-tabs-v3.component';
import { ListaInformacionTabsV2Component } from './components/listas/lista-informacion-tabs-v2/lista-informacion-tabs-v2.component';
import { FrmValidacionCoordenadasComponent } from './components/formularios/frm-validacion-coordenadas/frm-validacion-coordenadas.component';

import { ListaInformacionMultipleTabsV3Component } from './components/listas/lista-informacion-multiple-tabs-v3/lista-informacion-multiple-tabs-v3.component';
import { FiltroCompuestoOldComponent } from './components/filtro-compuesto/filtro-compuesto.component';
import { TablaDatosComponent } from './components/tabla-datos/tabla-datos.component';

import { AgGridModule } from 'ag-grid-angular';
import { SubsistemasV3Component } from './components/subsistemas/subsistemas-v3/subsistemas-v3.component';
import { ObjetosControlV3Component } from './components/objetos-control/objetos-control-v3/objetos-control-v3.component';
import { FrmValidacionCoordenadasSancionatorioComponent } from './components/formularios/frm-validacion-coordenadas-sancionatorio/frm-validacion-coordenadas-sancionatorio.component';
import { ShaSerDatosService } from './services/sha-ser-datos';
import { ListaInformacionV22Component } from './components/listas/lista-informacion-v2-2/lista-informacion-v2-2.component';
import { FraConDwhKnowageComponent } from './components/reporteadores/fra-con-dwh-knowage/fra-con-dwh-knowage.component';
import { CarruselImagenesV3Component } from './components/carrusel-imagenes/carrusel-imagenes-v3/carrusel-imagenes-v3.component';


@NgModule({
    exports: [
        CommonModule,
        NgbModule,
        TranslateModule,
        CommonModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,

        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,

        ToggleFullscreenDirective,
        SidebarDirective,
        ToggleFullscreenDirective,
        BlockSpecialCharsDirective,
        AllowOnlyNumberDirective,
        KeyCombineDirective,
        ListaInformacionComponent,
        ListaInformacionMultipleComponent,
        ListaInformacionMultipleTabsComponent,
        ListaInformacionTabsComponent,
        ListaInformacionTabsV2Component,
        ListaInformacionTabsV3Component,
        ListaInformacionV2Component,
        ListaInformacionV22Component,
        ListaInformacionV3Component,
        ListaNotificacionComponent,
        ListaNotificacionGruposComponent,

        ListaInformacionMultipleTabsV3Component,
        FiltroCompuestoOldComponent,

        PopupResumenListaComponent,
        PopupResumenListaV2Component,

        CargaMasivaComponent,

        GraficaComponent,
        GraficaV2Component,

        NavbarNotificacionComponent,
        NotificacionesComponent,
        NotificationSidebarComponent,
        ObjetosControlComponent,
        ObjetosControlComponentV2,
        SubsistemasComponent,
        SubsistemasComponentV2,
        TarjetaPrincipalComponent,

        MultiDatePickerComponent,
        ResumenInformacionComponent,

        DefaultPipe,
        MensajesPipe,

        CarruselImagenesComponent,
        BarraHerramComponent,
        CarruselImagenesConsultaComponent,
        CarruselImagenesV2Component,
        CarruselImagenesV3Component,

        TarjetIndicaComponent,
        CounterBubbleComponent,

        FrmValidacionCoordenadasComponent,
        TablaDatosComponent,
        SubsistemasV3Component,
        ObjetosControlV3Component,
        FrmValidacionCoordenadasSancionatorioComponent,

        
    ],
    imports: [
        ChartsModule,
        CommonModule,
        FormsModule,
        LeafletModule,
        NgbModule,
        NgSelectModule,
        NguCarouselModule,
        NgxDatatableModule,
        PerfectScrollbarModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        AgGridModule.withComponents([]),
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NotificationSidebarComponent,
        CustomizerComponent,

        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        BlockSpecialCharsDirective,
        AllowOnlyNumberDirective,
        KeyCombineDirective,
        CargadorIframeComponent,
        GraficaComponent,
        GraficaV2Component,
        
        NavbarNotificacionComponent,
        NotificacionesComponent,
        ObjetosControlComponent,
        ObjetosControlComponentV2,
        ReporteadorComponent,
        SubsistemasComponent,
        SubsistemasComponentV2,
        TarjetaPrincipalComponent,
        PopupResumenListaComponent,
        PopupResumenListaV2Component,
        MultiDatePickerComponent,
        ResumenInformacionComponent,
        CarruselImagenesComponent,
        CargaMasivaComponent,
        
        ListaInformacionComponent,
        ListaInformacionMultipleComponent,
        ListaNotificacionComponent,
        ListaNotificacionGruposComponent,
        ListaInformacionMultipleTabsComponent,
        ListaInformacionTabsComponent,
        ListaInformacionV2Component,
        ListaInformacionV22Component,
        ListaInformacionV3Component,
        ListaInformacionTabsV2Component,
        ListaInformacionTabsV3Component,
        FiltroCompuestoComponent,

        ListaInformacionMultipleTabsV3Component,
        FiltroCompuestoOldComponent,

        PopupReporteadorSencilloComponent,
        CarruselImagenesConsultaComponent,
        PopupComboComponent,
        PopupCarmasComponent,
        DefaultPipe,
        MensajesPipe,
        TarjetIndicaComponent,
        PopupComboListaComponent,
        CarruselImagenesV2Component,
        FilterCategory,
        CountNotificationCategory,
        CounterBubbleComponent,
        CarruselImagenesV3Component,

        FrmValidacionCoordenadasComponent,

        TablaDatosComponent,
        SubsistemasV3Component,
        ObjetosControlV3Component,
        FrmValidacionCoordenadasSancionatorioComponent,
        BarraHerramComponent,
        FraConDwhKnowageComponent,
   

    ],
    providers: [
        BarraHerramientasService,
        TablaDatosComponent,
        ShaSerDatosService
    ],
})
export class SharedModule { }
